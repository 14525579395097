import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { toNamespacedPath } from "path";
import { Link, navigate } from "gatsby";
import { LinkMapProp, LinkMap } from "./LinkMap"
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import MobileMenu from "./MobileMenu";

export const links: LinkMapProp[] = [
    {
        name: 'Therapists',
        url: '/therapists',
        current: false,
                page: true,
        links: [
            {
                name: 'Michelle VanDenHul',
                url: '/michelle-vandenhul',
                current: false,
                page: true,
            },
            {
                name: 'Erin Nielsen Ogdahl',
                url: '/erin-nielsen-ogdahl',
                current: false,
                page: true,
            },
            {
                name: 'Jennifer Gleason-Wilson',
                url: '/jennifer-gleason-wilson',
                current: false,
                page: true,
            },
            {
                name: 'Christine Ellis',
                url: '/christine-ellis',
                current: false,
                page: true,
            },
            {
                name: 'Melissa Steever',
                url: '/melissa-steever',
                current: false,
                page: true,
            },
            {
                name: 'Jeanne Bunkers',
                url: '/jeanne-bunkers',
                current: false,
                page: true,
            },
            {
                name: 'Myra Eben',
                url: '/myra-eben',
                current: false,
                page: true,
            },
        ]
    },
    {
        name: 'Contact',
        url: '/contact',
        current: false,
        page: true,
    },
    {
        name: 'HIPAA Notice',
        url: 'https://nebula.wsimg.com/478d9016b1009154cb0510f75515dd9a?AccessKeyId=62706617BF886F08969D&disposition=0&alloworigin=1',
        current: false,
        page: false,
    },
    {
        name: 'Good Faith Estimate',
        url: '/docs/good-faith-estimate.pdf',
        current: false,
        page: false,
    },
    {
        name: 'COVID 19 Notice',
        url: '/covid-19-notice',
        current: false,
        page: true,
    }
]

const MainNav = () => {

    return (

        <Disclosure as="nav" className="bg-white shadow-sm">
            {({ open }) => (
                <>
                    <div className="max-w-5xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex justify-between h-20">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <Link to="/">
                                        <StaticImage
                                            src="../../images/IntegrativeWellnesslogo.jpg"
                                            alt="Integrative Wellness logo"
                                            width={140}
                                            className="h-6 md:h-12 w-auto"
                                            layout="fixed"
                                        />
                                    </Link>
                                </div>
                                <div className="hidden sm:ml-14 sm:flex sm:space-x-8 my-3">
                                    {
                                        links.map((item, i) => (
                                            item.links?.length > 0 ? (
                                                    <Menu as="div" className="relative" key={i}>
                                                        {({ open }) => (
                                                            <>
                                                                <Menu.Button className="text-gray-500 inline-flex items-center px-1 pt-4 hover:border-b-2 text-lg font-medium hover:border-green-600 hover:text-gray-900">
                                                                    {item.name}
                                                                    <svg fill="currentColor" viewBox="0 0 20 20" className={`inline w-4 h-4 ml-1 transition-transform duration-200 transform ${open ? 'rotate-180' : 'rotate-0'}`}>
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                                    </svg>
                                                                </Menu.Button>

                                                                <Transition
                                                                    as={Fragment}
                                                                    show={open}
                                                                    enter="transition ease-out duration-200"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="origin-top-right absolute left-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
                                                                        {
                                                                            item.links.map((subItem, i2) => (
                                                                                <Menu.Item key={i2}>
                                                                                    {
                                                                                        () =>
                                                                                            (item.page === false) ?
                                                                                                (
                                                                                                    <a
                                                                                                        href={item.url + subItem.url}
                                                                                                        className="text-gray-500 items-center pt-1 text-lg font-medium hover:bg-green-200 hover:text-gray-900 block px-4 py-2"
                                                                                                        key={i}
                                                                                                    >
                                                                                                        {subItem.name}
                                                                                                    </a>
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    <Link
                                                                                                        activeClassName="bg-green-400 text-gray-900 border-b-2"
                                                                                                        className="text-gray-500 items-center pt-1 text-lg font-medium hover:bg-green-200 hover:text-gray-900 block px-4 py-2"
                                                                                                        key={i}
                                                                                                        to={item.url + subItem.url}
                                                                                                    >
                                                                                                        {subItem.name}
                                                                                                    </Link>
                                                                                                )
                                                                                    }
                                                                                </Menu.Item>
                                                                            ))
                                                                        }
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </>
                                                        )}
                                                    </Menu>
                                            ) :
                                                (item.page === false) ?
                                                (
                                                    <a
                                                        href={item.url}
                                                        className="text-gray-500 inline-flex items-center px-1 pt-1 hover:border-b-2 text-lg font-medium hover:border-green-600 hover:text-gray-900"
                                                        key={i}
                                                    >
                                                        {item.name}
                                                    </a>
                                                )
                                                :
                                                (
                                                    <Link
                                                        activeClassName="border-green-400 text-gray-900 border-b-2"
                                                        className="text-gray-500 inline-flex items-center px-1 pt-1 hover:border-b-2 text-lg font-medium hover:border-green-600 hover:text-gray-900"
                                                        key={i}
                                                        to={item.url}>{item.name}</Link>
                                            )
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <MobileMenu links={links} />
                </>
            )}
        </Disclosure>
    );
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}




// <nav className="bg-white shadow">
//     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex justify-between h-20">
//             <div className="flex">
//                 <div className="flex-shrink-0 flex items-center">
//                     <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// </nav>
{/* <StaticImage
    src="./logo.jpg"
    alt="Integrative Wellness logo"
    width={200}
    className="block h-8 w-auto"
/>
<LinkMap links={links} /> */}
export default MainNav;