import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { Helmet } from "react-helmet"
import Menu from "../Menu/Menu"

const config = {
  SITE_TITLE: 'Integrative Wellness',
  SITE_DESCRIPTION: 'Professional Therapy Mental Health Services Sioux Falls, SD',
  DOMAIN: 'https://integrativewellnesssd.com/'
}

const Layout = (props: React.PropsWithChildren<{ full?: boolean, removeTopSpace?: boolean, className?: string }>) => {
  return (
    <div className={`bg-white ${props.className}`}>
      <Helmet
        meta={[
          { name: "HandheldFriendly", content: "True" },
          { name: "description", content: config.SITE_DESCRIPTION },
          { property: "og:site_name", content: config.SITE_TITLE },
          { property: "og:type", content: "website" },
          { property: "og:title", content: config.SITE_TITLE },
          { property: "og:description", content: config.SITE_DESCRIPTION },
          { property: "og:url", content: config.DOMAIN },
          { name: "twitter:card", content: "summary" },
          { name: "twitter:title", content: config.SITE_TITLE },
          { name: "twitter:description", content: config.SITE_DESCRIPTION },
          { name: "twitter:url", content: config.DOMAIN },
        ]}
      />
      <Menu />
      <div className={`${props.full ? 'max-w-full' : 'sm:px-6 lg:px-8 max-w-5xl mx-auto'} ${props.removeTopSpace ? '' : 'mt-12  px-2 sm:px-6 lg:px-8'}`}>
        {props.children}
      </div>

      <footer className='sm:px-6 lg:px-8 bg-gray-800 text-white mt-8 pt-4 text-center'>
        <div className="max-w-5xl mx-auto sm:px-6 lg:px-8 text-xl">
          <div className="italic">
            <p className="mb-2">We accept Medicaid, Medicare, most insurances, &amp; private pay</p>
            <p>Call us today <a href="tel:1-605-271-1348">605.271.1348</a> to schedule an appointment</p>
          </div>

          <div className='text-xs py-4'>
            Copyright @ Integrative Wellness. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout
