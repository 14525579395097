import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { toNamespacedPath } from "path";
import { Link, navigate } from "gatsby";
import { LinkMapProp, LinkMap } from "./LinkMap"
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'

const defaultLinkClass = "border-transparent text-gray-500 block pl-3 pr-4 py-2 border-l-4 text-base font-medium hover:bg-green-50 hover:border-green-200 hover:text-gray-700"

const MobileMenu = (props: { links: LinkMapProp[] }) => (
    <Disclosure.Panel className="sm:hidden">
        <div className="pt-2 pb-4 space-y-1">
            <Link
                to='/'
                className={defaultLinkClass}
            >
                Home
            </Link>
            {
                props.links.map((item, i) => (
                    <div key={i}>
                        <>
                            {
                                (item.page === false) ?
                                    (
                                        <a
                                            href={item.url}
                                            className={defaultLinkClass}
                                            key={i}
                                        >
                                            {item.name}
                                        </a>
                                    )
                                    :
                                    (
                                        <Link
                                            to={item.url}
                                            className={defaultLinkClass}
                                        >
                                            {item.name}
                                        </Link>
                                    )
                            }
                        </>
                        <>
                            {
                                item.links?.map((item2, i2) =>
                                    (item.page === false) ?
                                        (
                                            <a
                                                href={item.url + item2.url}
                                                className={defaultLinkClass}
                                                key={i}
                                            >
                                                {item2.name}
                                            </a>
                                        )
                                        :
                                        (
                                            <div key={i2} className="pl-3 my-1">
                                                <Link
                                                    to={item.url + item2.url}
                                                    className={defaultLinkClass}
                                                >
                                                    {item2.name}
                                                </Link>
                                            </div>
                                        )
                                )
                            }
                        </>
                    </div>
                ))
            }
        </div>
    </Disclosure.Panel>
)

export default MobileMenu